import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/contatos.png'

import { 
  HeaderBanner,
} from '../components/styles/Base.styles'

import {
  ctaContent,
  boxContentL,
  boxContentR,
  formulario,
  label,
  faleConosco,
  trabalheConosco,
  formsStyle,
  btnEnviar,
  btnAnexar
} from '../components/styles/Teste.styles'

const Contatos = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Fale Conosco | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={ctaContent}>
      <div css={formsStyle}>
        <div css={faleConosco}>
          <div css={boxContentL}>
            <h1 style={{ marginTop: '-23px' }}>Fale<br /> Conosco</h1>
            <p>Nós estamos muito interessados no que você tem a dizer. Criamos este espaço para você realizar seus comentários, dar sugestões e esclarecer dúvidas. Escreva para nós! É fácil e rápido!</p>
            <p>Você pode utilizar o formulário abaixo ou os demais dados de contato.</p>
            <p>Teremos o maior prazer em respondê-lo!</p>
            <div css={formulario}>
              <form style={{ margin: '0 auto' }}>
                <div css={label}>
                  <input placeholder='Nome' type="text" name="name" id="name" required />
                </div>
                <div css={label}>
                  <input placeholder='E-mail' type="text" name="email" id="email" required />
                </div>
                <div css={label}>
                  <textarea placeholder='Mensagem' name="message" id="message" rows="4" />
                </div>
                <button type="submit" css={btnEnviar} style={{ marginTop: 56 }}>ENVIAR</button>
              </form> 
            </div>
          </div>
        </div>
        <div css={trabalheConosco}>
          <div css={boxContentR}>
            <h1>Trabalhe<br /> Conosco</h1>
            <p>Ficamos felizes no seu interesse em fazer parte do time Kingdom! Nós buscamos profissionais que se identificam com nossa visão, missão e valores e estejam dispostos a viver os desafios propostos em equipe.</p>
            <p>Caso você tenha se identificado conosco, envie seu currículo pelo formulário abaixo.</p>
            <p>Nossa equipe de Gestão de Pessoas retornará o mais breve possível.</p>
            <div css={formulario}>
              <form style={{ margin: '0 auto' }}>
                <div css={label}>
                  <input placeholder='Nome' type="text" name="name" id="name" required />
                </div>
                <div css={label}>
                  <input placeholder='E-mail' type="text" name="email" id="email" required />
                </div>
                <div css={label}>
                  <textarea placeholder='Mensagem' name="message" id="message" rows="3" />
                </div>
                <div style={{ marginTop: 56 }}>
                  {/* <button type="submit" css={btnAnexar} style={{ marginRight: 32 }}>ANEXAR</button> */}
                  <button type="submit" css={btnEnviar}>ENVIAR</button>
                </div>
              </form> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default Contatos
